@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Roboto:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&family=Titillium+Web:wght@200;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Jost:wght@100;200;300;400;500;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&family=Titillium+Web:wght@200;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Jost:wght@100;200;300;400;500;700&family=Libre+Baskerville:wght@400;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&family=Titillium+Web:wght@200;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');

body{
    background: #f5f6fa;
    font-family: 'Jost', sans-serif;
    /*background: linear-gradient(0deg, rgba(206,214,224,1) 29%, rgba(223,228,234,1) 40%, rgba(241,242,246,1) 84%, rgba(255,255,255,1) 96%);*/
}
nav{
    /*height: 6rem;*/
    /*width: 100vw;*/
    /*display: flex;*/
    /*position: fixed;*/
    z-index: 10;
    background: #2e86de;
}

/*Styling logo*/
.logo{
    padding:1vh 1vw;
    text-align: center;
}
.logo img {
    object-fit: contain;
}
.show-login-btn{
    display: none;
}

/*Styling Links*/
.nav-links{
    display: flex;
    /*list-style: none;*/
    /*width: 88vw;*/
    padding: 0 0.7vw;
    /*justify-content: space-evenly;*/
    align-items: center;
    color: #fff;
    font-weight: bold;
    /*text-transform: uppercase;*/
}
.nav-links li a{
    text-decoration: none;
    margin: 0 0.7vw;
}
.nav-links li a:hover {
    color: #ff9f43;
}
.nav-links li {
    position: relative;
}
.nav-links li a::after {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    background-color: #ff9f43;
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
}
.nav-links li a:hover::after{
    width: 80%;
}

/*Styling Buttons*/
.login-button{
    background-color: transparent;
    color: #fff;
    border: 1.5px solid #f2f5f7;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
    /*margin-left: 2vw;*/
    font-size: 1rem;
    cursor: pointer;

}
.login-button:hover {
    color: #ff9f43;
    background-color: #f2f5f7;
    border:1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}
.join-button{
    color: #fff;
    background-color: #ff9f43;
    border: 1.5px solid #ff9f43;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
}
.join-button:hover {
    color: #ff9f43;
    background-color: transparent;
    border:1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div{
    width: 30px;
    height:3px;
    background: #f2f5f7;
    margin: 5px;
    transition: all 0.3s ease;
}
.hamburger{
    display: none;
}
#web-slid{
    display: initial;
    width: 100vh;
    height: 100vh;
}
#web-slid img{
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: cover !important;
    /*height: auto;*/
}
#phone-slid{
    display: none;
}

#header-servcies{
    background: #c77728;
}
#header-servcies:hover{
    background: rgba(32, 22, 63, 1);
}


/*Animating Hamburger Icon on Click*/
.toggle .line1{
    transform: rotate(-45deg) translate(-5px,6px);
}

.toggle .line2{
    transition: all 0.7s ease;
    width:0;
}
.toggle .line3{
    transform: rotate(45deg) translate(-5px,-6px);
}
header {
    position: relative;
    /*height: 800px;*/
    /*min-height: 500px;*/
    /*width: 100%;*/
    /*background: #161415 url(./images/header-backgroundj.jpg) no-repeat top center;*/
    background-size: cover !important;
    -webkit-background-size: cover !important;
    /*text-align: center;*/
    overflow: hidden;
}

header #header-content{
    bottom: 20%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    /*height: 50%;*/
}
header #header-content p{
    font-family: 'Libre Baskerville', serif;
    line-height: 1.5;
}
#slider img{
    object-fit: contain;

}

.book-btn{
    background: #2e86de;
}

.text-pink{
    color: #ff9f43;
}
.text-purple{
    color: #2e86de;
}
.bg-purple{
    background-color: #c77728;
}

.card {
    font-family: "Jost", sans-serif;
    width: 90%;
    max-width: 350px;
    background: #2e86de;
    padding: 0 2rem 1.5rem 2rem;
    border-radius: 10px;
    transition: box-shadow .3s linear;
}
.card:hover {
    box-shadow: 0 0 35px rgba(0, 0, 0, .3);
}

.card .card-img {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 25px rgba(0, 0, 0, .3);
    transform: translateY(-15%);
    transition: box-shadow .3s linear;
}
.card:hover .card-img {
    box-shadow: none;
}
.card .card-img img {
    width: 100%;
    height: 160px;
    display: block;
}

.card .card-content {
    text-align: center;
}
.card .card-content .big-title {
    color: #ffffff;
    font-size: 1.3rem;
}
.card .card-content .medium-title {
    color: #ffffff;
    font-size: .85rem;
    margin-top: .3rem;
}












/*Stying for small screens*/
@media screen and (max-width: 800px){
    nav{
        position: fixed;
        z-index: 3;
    }
    .hamburger{
        display:block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
        color: #fff;
    }
    .toggle{
         color: #fff;
     }
    .nav-links{
        position: fixed;
        right: 0;
        background: #2e86de;
        height: 55vh;
        width: 60%;
        border-radius: 0 0 0 20px;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        padding-top: 5rem;
        align-items: initial;
    }
    .nav-links.open{
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
    }
    .nav-links li{
        opacity: 0;
        color : #fff;
    }

    .nav-links li a::after {
        content: "";
        display: block;
        height: 1px;
        width: 80%;
        background-color: #fff;
        position: absolute;
        transition: all ease-in-out 250ms;
        margin: inherit;
    }
    .nav-links li:nth-child(1){
        transition: all 0.5s ease 0.2s;
    }
    .nav-links li:nth-child(2){
        transition: all 0.5s ease 0.4s;
    }
    .nav-links li:nth-child(3){
        transition: all 0.5s ease 0.6s;
    }
    .nav-links li:nth-child(4){
        transition: all 0.5s ease 0.7s;
    }
    .nav-links li:nth-child(5){
        transition: all 0.5s ease 0.8s;
    }
    .nav-links li:nth-child(6){
        transition: all 0.5s ease 0.9s;
        margin: 0;
    }
    .nav-links li:nth-child(7){
        transition: all 0.5s ease 1s;
        margin: 0;
    }
    li.fade{
        margin: 2rem 0 0 1rem;
        opacity: 1;
    }

    /*.fade .login-button{*/
    /*    margin: 2rem 0 0 1rem;*/
    /*}*/
    #phone-slid{
        display: initial !important;
    }
    #web-slid{
        display: none !important;
    }
    .show-login-btn{
        display: inline-grid;
        gap: 1rem;
        width: 85%;
        margin: auto !important;
    }
    .phone-slid-img{
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
    }
}
